@import "./colors.scss";
@import "../typography/typography.scss";


.ws-no-wrap{
    white-space: nowrap;
}

.w-fit-content{
    width: fit-content;
}