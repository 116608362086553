@import "../../../scss/utils/util.scss";
@media only screen and (max-width: 765px) {
    .col-small-1 {
        width: 11.33333% !important;
    }
    .col-small-8 {
        width: 64.66667% !important;
    }
    .col-small-3 {
        width: 24% !important;
    }
}
.mt-8rem {
    margin-top: 8rem !important;
}
@media only screen and (max-width: 765px) {
    .px-sm-05 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }
}
