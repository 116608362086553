@import "../../../scss/utils/util.scss";

.avatar {
  vertical-align: middle;
  width: 75px !important;
  height: 75px !important;
  border-radius: 50%;
  object-fit: cover;
}

@media (min-width: 300px) and (max-width: 350px) {
  #scard {
    border: solid transparent;
  }
}
/*meduim mobile phones*/
@media (min-width: 350px) and (max-width: 400px) {
  #scard {
    border: solid transparent;
  }
}
/*large mobile phones*/
@media (min-width: 400px) and (max-width: 500px) {
  #scard {
    border: solid transparent;
  }
}
.list-group-flush {
  list-style: none;
}
@media only screen and (min-width: 765px) {
  .b-none {
    border: none !important;
  }
}
.pwd-accordion {
  .accordion-item .accordion-button {
    border-radius: 0;
    box-shadow: none;
    color: $dark !important;
  }
  .accordion-button:not(.collapsed) {
    color: $dark !important;
    background-color: transparent;
    box-shadow: none;
    &::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
    }
  }
  .accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
  }
}
