@import "/node_modules/bootstrap/scss/functions";

//Include any default variable overrides here
@import "../../scss/utils/colors.scss";
@import "../../scss/utils/util.scss";
@import "../../scss/typography/typography.scss";

@import "/node_modules/bootstrap/scss/variables";
@import "/node_modules/bootstrap/scss/mixins";
@import "/node_modules/bootstrap/scss/utilities";

@import "/node_modules/bootstrap/scss/root";
@import "/node_modules/bootstrap/scss/reboot";
@import "/node_modules/bootstrap/scss/type";
@import "/node_modules/bootstrap/scss/containers";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/utilities/api";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/transitions";

.app {
    .custom-btn-user:hover{
        color: $dark !important;
        background-color: $white !important;
    }
    background: $bg-color;
    .bg-green {
        background-color: $green !important;
    }
    .bg-dark{
        background-color: $dark !important;
    }
    .bg-white {
        background-color: $white;
    }
    .text-grey{
        color: $grey !important;
    }
    .text-light {
        color: $white !important;
    }
    .text-dark {
        color: $dark !important;
    }
    .text-dark-grey{
        color: $dark-grey !important;
    }
    .text-danger {
        color: $danger !important;
    }
    .a-nonlink {
        color: $dark !important;
        text-decoration: none;
        &:hover {
            color: $dark !important;
        }
    }
    .w-col-30 {
        @media only screen and (min-width: 765px) {
            width: 30% !important;
        }
    }
    .w-col-md-22 {
        @media only screen and (min-width: 765px) {
            width: 22% !important;
        }
    }
    .p-2rem {
        @media only screen and (min-width: 765px) {
            padding: 2rem !important;
        }
        @media only screen and (max-width: 765px) {
            padding: 1rem !important;
        }
    }

    .text-green {
        color: $green !important;
    }
    .bg-white {
        background-color: $white;
    }
    .b-r-51 {
        border-radius: 51px;
    }
    .rounded-card {
        background: $white;
        border: 1px solid $border-color;
        box-sizing: border-box;
        border-radius: 16px;
    }
    .rounded-btn {
        background: $white;
        border: 1px solid $primary !important;
        box-sizing: border-box;
        border-radius: 16px !important;
    }
    .btn-box {
        background: $white;
        border: 1px solid $primary !important;
        box-sizing: border-box;
        border-radius: 4px !important;
    }
   
    .my-md-100 {
        @media only screen and (min-width: 765px) {
            padding-top: 100px !important;
        }
        @media only screen and (max-width: 765px) {
            padding-top: 80px !important;
            padding-bottom: 100px !important;
        }
    }
    .my-md-200 {
        margin-top: 100px !important;
        @media only screen and (max-width: 765px) {
            margin-bottom: 200px !important;
        }
    }
    .z-ind-1 {
        z-index: 1;
    }
    .z-ind-0 {
        z-index: 0;
    }
    .top-box {
        background: $white;
        border-top: 1px solid $primary !important;
        border-bottom: 1px solid $primary !important;
        box-sizing: border-box;
        border-radius: 4px !important;
    }
    .ul-list {
        margin: 0;
        padding-left: 0.6rem;
        list-style: none;
    }
    // .bottom-btn{
    //     background: $white 0% 0% no-repeat padding-box;
    // // @media only screen and (max-width: 765px) {
    //     bottom: 27%;
    // // }
    // // @media only screen and (min-width: 765px) {
    // //     bottom: 0;
    // // }
    //     position: fixed;
    //     box-shadow: 0 3px 10px grey;
    // }
    .bottom-btn {
        background: $white 0% 0% no-repeat padding-box;
        box-shadow: 0 3px 10px grey;
        overflow: hidden;
        position: fixed;
        @media only screen and (max-width: 765px) {
            bottom: 11%;
        }
        @media only screen and (min-width: 765px) {
            bottom: 0;
        }
        width: 100%;
    }
    .btn-light-green{
        background-color: $light-green;
    }
    .btn-none{
        cursor: default;
    }
    .btn {
        &:focus {
            box-shadow: none !important;
        }
    }
    .br-grey {
        border-color: $border-color !important;
    }
    .top-navbar {
        background: $white 0% 0% no-repeat padding-box;
        top: 0;
        position: fixed;
        border-bottom: 1px solid $border-color;
        width: 100%;
        .nav-link {
            color: $dark !important;
        }
    }
    .bottom-navbar {
        background: $white 0% 0% no-repeat padding-box;
        bottom: 0;
        position: fixed;
        border-top: 2px solid $light-grey;
        padding-top: 0px;
        width: 100%;
        .nav-item {
            color: $light-grey;
            border: none;
            &:hover,
            &:focus,
            &:active {
                color: $primary;
                border-top: 2px solid $primary;
            }
        }
        .active {
            color: $primary;
            border-top: 2px solid $primary;
        }
    }
    .bg-transparent {
        background-color: transparent !important;
    }
    .login-input {
        background: $white !important;
        border: 2px solid $border-grey !important;
        box-sizing: border-box;
        border-radius: 9px !important;
    }
    ::-webkit-input-placeholder {
        color: $dark-placeholder !important;
    }
    .login-input:focus {
        box-shadow: none !important;
    }
    .w-md-65 {
        @media only screen and (min-width: 1500px) {
            width: 64%;
        }
    }
    .w-md-50 {
        @media only screen and (min-width: 1500px) {
            width: 50%;
        }
    }
    .cart-snackbar {
        .MuiSnackbarContent-root {
            color: $dark;

            padding: 6px 6px;

            font-size: 0.875rem;
            align-items: center;
            line-height: 1.43;
            border-radius: 4px;
            letter-spacing: 0.01071em;
            background-color: $white;

            // @media only screen and (max-width: 600px) {
            //     margin-top: -178px;
            // }
        }
    }
    .btn-undo {
        text-transform: none !important;
    }
    .searchbar {
        border: 2px solid #c6c6c6;
        box-sizing: border-box;
        border-radius: 4px;
        background-color: transparent;
        padding: 5px 24px;
        .MuiSvgIcon-root {
            fill: $dark !important;
        }
    }
    .search-dropdown {
        padding: 1rem 1rem !important;
        // inset: 10px auto auto 0px !important;
        border-radius: 0px !important;
        @media only screen and (min-width: 765px) {
            // margin-top: 1.3rem !important;
        }
        background-color: $secondary;
    }
    .sticky-div {
        position: fixed;
        bottom: 65px;
        background-color: $secondary;
    }
    .perimeter {
        max-width: 1024px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
    }

    .image {
        flex: 0 0 100%;
    }

    .copy {
        padding: 20px;
        font-family: sans-serif;
    }

    @media (min-width: 415px) {
        .perimeter {
            flex-direction: row;
        }

        .image {
            flex: 0 0 50%;
        }
    }

    @media (min-width: 800px) {
        .image {
            flex: 0 0 33.5%;
        }
    }
    .w-fit-content {
        width: fit-content;
    }
    .img-zoom {
        overflow: hidden;
    }

    .img-zoom img {
        width: 100%;
        transition: 0.5s all ease-in-out;
    }

    .img-zoom:hover img {
        transform: scale(1.5);
    }
}
.Snackbar_snackbar__text__1Hx2a {
    font-family: $font-family !important;
}
.pointer {
    cursor: pointer;
}
.w-10 {
    width: 10% !important;
}
