@import "../../../scss/utils/util.scss";

.login-outer-container {
    background: url("/assets/banner1.png") no-repeat;
    // background-color: aqua;
    height: auto;
    width: 100%;
    top: 0;
    overflow: hidden;
    // .h-row1 {
    //     height: 30vh;
    // }
    //  .h-row2 {
    //     height: 70vh;
    // }
    font-family: $font-family;
}
.loginBody{
    @media only screen and (max-width: 765px) {
        height: 100vh;
        background-image: linear-gradient(to right, #8EE839,#2BC923)
    }
}
.login-container {
    position: absolute;
    top: 45%;
    background: red;
    background: $white;
    @media only screen and (min-width: 765px) {
        border: 1px solid #c6c6c6;
    }
    max-width: 550px !important; 
    box-sizing: border-box;
    border-radius: 4px;
    // margin-top: -50px !important;
}

.forgot-password-container {
    position: absolute;
    top: 30%;
    max-width: 550px !important;
    background: red;
    background: $white;
    @media only screen and (min-width: 765px) {
        border: 1px solid #c6c6c6;
    }
    box-sizing: border-box;
    border-radius: 4px;
    // margin-top: -50px !important;
}

.p-07rem {
    padding: 0.5rem !important;
}
.btn-focus:focus {
    box-shadow: none !important;
}

.banner {
    width: 100%;
    @media only screen and (min-width: 765px) {
        height: 100vh;
    }
    overflow: hidden;
}
.mt-md-50 {
    @media only screen and (min-width: 765px) {
        margin-top: -5% !important;
    }
    @media only screen and (max-width: 765px) {
        margin-top: 30% !important;
    }
}
.pointer{
    cursor: pointer;
}

// .modal-backdrop{
//     z-index: -1050 !important;
//     width: 0 !important;
//     height: 0 !important;
// }