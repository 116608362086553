@import "../../../scss/utils/util.scss";

$white: #ffffff;
$dark-blue: #000000;

.login-outer-container {
    background: url("/assets/banner1.svg") no-repeat;
    // background-color: aqua;
    height: auto;
    width: 100%;
    top: 0;
    overflow: hidden;
    // .h-row1 {
    //     height: 30vh;
    // }
    //  .h-row2 {
    //     height: 70vh;
    // }
    font-family: $font-family;
}
.login-container {
    // position: absolute;
    background: red;
    background: $white;
    @media only screen and (min-width: 765px) {
        border: 1px solid #c6c6c6;
    }
    box-sizing: border-box;
    border-radius: 21px;
    box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.25);
    // margin-top: -50px !important;
}

.p-07rem {
    padding: 0.7rem !important;
}
.btn-focus:focus {
    box-shadow: none !important;
}

.admin-banner {
    width: 100%;
    height: auto;
}
.mt-md-50 {
    @media only screen and (min-width: 765px) {
        margin-top: -2% !important;
    }
    @media only screen and (max-width: 765px) {
        margin-top: 30% !important;
    }
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: $primary !important;
    &:hover {
        background-color: white !important;
    }
}
.MuiIconButton-colorSecondary:hover {
    background-color: white !important;
}
