@import "../../scss/utils/util.scss";

.splashLogo{
    animation: splash 3s;
}

@keyframes splash {
    0% {transform: scale(0.1);}
  100% {transform: scale(1);
  		opacity:0;}
}