@import "../../../scss/utils/util.scss";

.search-input {
    border: 0 !important;
    outline: 0 !important;
    background: transparent;
    width: 100% !important;
}

.circle-profile {
    background: #c6c6c6;
    border: 1px solid #c6c6c6;
    box-sizing: border-box;
    height: 50px;
    width: 50px;
    border-radius: 51px;
    display: flex;
    color: $light;
}
.shopping-cart {
    .MuiBadge-colorSecondary {
        color: $light;
        background-color: $green !important;
    }
    .MuiSvgIcon-root {
        height: 1.3em !important;
        width: 1.3em !important;
    }
    .MuiBadge-badge {
        font-size: 12px;
        font-family: $font-family;
    }
}
.img-text {
    position: relative;
    text-align: center;
    color: white;
}
.btn-left-text {
    position: absolute;
    top: 50%;
    right: 0%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    text-align: right !important;
}
.topbar {
    background: #ffffff 0% 0% no-repeat padding-box;
    top: 0;
    position: fixed;
    border-bottom: 1px solid #e1e1e1;
}
