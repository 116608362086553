@import "../../../scss/utils/util.scss";

.pay-rad-btn {
    .form-check-input {
        // background-color: $green !important;
        border-color: $dark !important;
        box-shadow: none !important;
        // &:focus{

        // }
    }
    .form-check-input:checked {
        background-color: $green !important;
        border-color: $green !important;
    }
}
