@import "../../../scss/utils/util.scss";
.table-container {
    background: $white;
    box-shadow: $box-shadow-color;
    border-radius: 16px;
    overflow: hidden;
    .table {
        --bs-table-striped-bg: #e2e6ea80;
        .tr {
            display: inline-block;
        }
        .break {
            float: left;
            line-height: 22px;
        }
    }
}
.pagination-btn {
    width: 40px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #e1e1e1;
    box-sizing: border-box;
    border-radius: 21px;
    text-align: center;
}
.action-circle {
    width: 35px;
    height: 35px;
    background: #ffffff;
    border: 1px solid #e1e1e1;
    box-sizing: border-box;
    box-shadow: 0px 0px 4px rgba(21, 131, 255, 0.2);
    border-radius: 21px;
    text-align: center;
}
.custom-pagination {
    .css-rppfq7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
        border: 1px solid $primary;
        color: $primary;
        background-color: transparent;
    }
    .css-n8417t-MuiSvgIcon-root-MuiPaginationItem-icon {
        color: $primary;
    }
    .css-rppfq7-MuiButtonBase-root-MuiPaginationItem-root {
        font-family: $font-family;
        font-size: 14px;
    }
    .css-wjh20t-MuiPagination-ul {
        justify-content: center;
    }
}
.custom-checkbox {
    .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
        color: $primary;
    }
    .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
        background-color: transparent;
        border: 1px solid $primary;
    }
    .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase {
        color: $grey;
    }
    .css-1yjjitx-MuiSwitch-track {
        background-color: transparent;
        border: 1px solid $grey;
    }
    .css-ahj2mt-MuiTypography-root {
        font-size: $f10 !important;
        font-family: $font-family !important;
    }
}
.search-input-btn {
    .form-control:focus {
        box-shadow: none;
    }
    .form-control::-webkit-input-placeholder {
        color: $grey !important;
    }
}

.search-input-btn {
    padding: 2px 5px 2px 5px;
    border-radius: 10px;
    height: 40px;
    float: right;
    position: relative;
    transition: width 0.5s;
    -webkit-transition: width 0.5s;
    text-align: center;
    overflow: hidden;

    border-radius: 21px;
}

.max-modal-height {
    max-height: 65vh;
    overflow-y: scroll;
}

.vendor-modal-body {
    padding-top: 0 !important;
}
