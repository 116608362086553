$primary: #5ca772;
$secondary: #fbfffd;
$dark: #121212;
$info: #7e7e7e;
$light: #e2e2e2;
$grey: #c6c6c6;
$dark-grey: #a9a9a9;
$blue: #074ee8;
$light-grey: #c6c6c6;
$danger: #a75c5c;
$green: #5ca772;
$border-grey: #e1e1e1;
$dark-placeholder: #111111;
$bg-color: #fbfffd;
$white: #ffffff;
$light-green: #d9eddf;
$box-shadow-color: 0px 0px 5px rgba(92, 167, 114, 0.25);
