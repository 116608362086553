@import "../../../scss/utils/util.scss";

.rounded-bg {
    background: $white;
    box-shadow: $box-shadow-color;
    border-radius: 16px;
}
.box-card {
    background: $white;
    box-shadow: $box-shadow-color;
    border-radius: 21px;
}
.recharts-wrapper {
    font-family: $font-family !important;
    font-size: $f14;
}
.vertical-rule{
    width: .3%;
    background-color: $grey;
}