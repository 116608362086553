@import "/node_modules/bootstrap/scss/functions";

//Include any default variable overrides here
@import "../../scss/utils/colors.scss";
@import "../../scss/utils/util.scss";
@import "../../scss/typography/typography.scss";

@import "/node_modules/bootstrap/scss/variables";
@import "/node_modules/bootstrap/scss/mixins";
@import "/node_modules/bootstrap/scss/utilities";

@import "/node_modules/bootstrap/scss/root";
@import "/node_modules/bootstrap/scss/reboot";
@import "/node_modules/bootstrap/scss/type";
@import "/node_modules/bootstrap/scss/containers";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/utilities/api";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/tables";
.admin-panel {
    background: $bg-color;

    .bg-green {
        background-color: $green !important;
    }
    .bg-white {
        background-color: $white;
    }
    .text-light {
        color: $white !important;
    }
    .text-grey {
        color: $grey !important;
    }
    .text-dark {
        color: $dark !important;
    }
    .text-danger {
        color: $danger !important;
    }
    .a-nonlink {
        color: $dark !important;
        text-decoration: none;
        &:hover {
            color: $dark !important;
        }
    }
    .nonlink {
        text-decoration: none;
        &:hover {
            color: $blue !important;
        }
    }
    .w-col-30 {
        @media only screen and (min-width: 765px) {
            width: 30% !important;
        }
    }
    .w-col-md-22 {
        @media only screen and (min-width: 765px) {
            width: 22% !important;
        }
    }
    .p-2rem {
        @media only screen and (min-width: 765px) {
            padding: 2rem !important;
        }
        @media only screen and (max-width: 765px) {
            padding: 1rem !important;
        }
    }
    .text-green {
        color: $green !important;
    }
    .bg-white {
        background-color: #ffffff;
    }
    .b-r-51 {
        border-radius: 51px;
    }
    .rounded-card {
        background: #ffffff;
        border: 1px solid #e1e1e1;
        box-sizing: border-box;
        border-radius: 16px;
    }
    .rounded-btn {
        background: #ffffff;
        border: 1px solid #5ca772 !important;
        box-sizing: border-box;
        border-radius: 16px !important;
    }
    .btn-box {
        background: #ffffff;
        border: 1px solid #5ca772 !important;
        box-sizing: border-box;
        border-radius: 4px !important;
    }
    .my-md-100 {
        @media only screen and (min-width: 765px) {
            margin-top: 100px !important;
        }
        @media only screen and (max-width: 765px) {
            margin-top: 80px !important;
            margin-bottom: 100px !important;
        }
    }
    .my-md-200 {
        margin-top: 100px !important;
        @media only screen and (max-width: 765px) {
            margin-bottom: 200px !important;
        }
    }
    .z-ind-1 {
        z-index: 1;
    }
    .z-ind-0 {
        z-index: 0;
    }
    .top-box {
        background: #ffffff;
        border-top: 1px solid #5ca772 !important;
        border-bottom: 1px solid #5ca772 !important;
        box-sizing: border-box;
        border-radius: 4px !important;
    }
    .ul-list {
        margin: 0;
        padding-left: 0.6rem;
        list-style: none;
    }
    // .bottom-btn{
    //     background: #FFFFFF 0% 0% no-repeat padding-box;
    // // @media only screen and (max-width: 765px) {
    //     bottom: 27%;
    // // }
    // // @media only screen and (min-width: 765px) {
    // //     bottom: 0;
    // // }
    //     position: fixed;
    //     box-shadow: 0 3px 10px grey;
    // }
    .bottom-btn {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0 3px 10px grey;
        overflow: hidden;
        position: fixed;
        @media only screen and (max-width: 765px) {
            bottom: 11%;
        }
        @media only screen and (min-width: 765px) {
            bottom: 0;
        }
        width: 100%;
    }
    .btn {
        &:focus {
            box-shadow: none !important;
        }
    }
    .br-grey {
        border-color: $border-color !important;
    }
    .top-navbar {
        background: #ffffff 0% 0% no-repeat padding-box;
        top: 0;
        position: fixed;
        border-bottom: 1px solid #e1e1e1;
        width: 100%;
        .nav-link {
            color: $dark !important;
        }
    }
    .bottom-navbar {
        background: #ffffff 0% 0% no-repeat padding-box;
        bottom: 0;
        position: fixed;
        border-top: 2px solid $light-grey;
        padding-top: 0px;
        width: 100%;
        .nav-item {
            color: $light-grey;
            border: none;
            &:hover,
            &:focus,
            &:active {
                color: $primary;
                border-top: 2px solid $primary;
            }
        }
        .active {
            color: $primary;
            border-top: 2px solid $primary;
        }
    }
    .bg-transparent {
        background-color: transparent !important;
    }
    .login-input {
        // background: $white !important;
        border: 1px solid $border-grey !important;
        box-sizing: border-box;
        border-radius: 21px !important;
    }
    ::-webkit-input-placeholder {
        color: $grey !important;
    }

    input[type="number"] {
        -moz-appearance: textfield;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    .login-input:focus {
        box-shadow: none !important;
    }
    .w-md-65 {
        @media only screen and (min-width: 1500px) {
            width: 64%;
        }
    }
    .w-md-50 {
        @media only screen and (min-width: 1500px) {
            width: 50%;
        }
    }
    .cart-snackbar {
        .MuiSnackbarContent-root {
            color: $dark;

            padding: 6px 6px;

            font-size: 0.875rem;
            align-items: center;
            line-height: 1.43;
            border-radius: 4px;
            letter-spacing: 0.01071em;
            background-color: $white;

            // @media only screen and (max-width: 600px) {
            //     margin-top: -178px;
            // }
        }
    }
    .btn-undo {
        text-transform: none !important;
    }
    .searchbar {
        border: 2px solid #c6c6c6;
        box-sizing: border-box;
        border-radius: 4px;
        background-color: transparent;
        padding: 5px 24px;
        .MuiSvgIcon-root {
            fill: $dark !important;
        }
    }
    .search-dropdown {
        padding: 1rem 1rem !important;
        // inset: 10px auto auto 0px !important;
        border-radius: 0px !important;
        @media only screen and (min-width: 765px) {
            margin-top: 1.3rem !important;
        }
        background-color: $secondary;
    }
    .sticky-div {
        position: fixed;
        bottom: 65px;
        background-color: $secondary;
    }
    .br-21 {
        border-radius: 21px !important;
    }
    .text-blue {
        color: $blue;
    }
    .sidenav-group {
        margin: 0;
        padding: 0;
        .sidenav-item {
            margin-bottom: 5px;
            display: block;
            color: $light;
            transition: 0.5s;
            position: relative;
            padding-top: 15px;
            padding-bottom: 15px;
            text-decoration: none;

            // &:hover,
            &.active {
                color: $primary;
                background: $white;
            }
            &.active {
                background: $white;
                // &::after {
                //     content: "";
                //     width: 4px;
                //     height: 100%;
                //     background: $white;
                //     left: 0%;
                //     top: 0%;
                //     position: absolute;
                // }
            }

            .sidenav-item-text {
                vertical-align: auto;
            }
        }
        .nav-pills .nav-link {
            border-radius: 21px 0px 0px 21px !important;
        }
    }
    .pl-2rem {
        padding-left: 2rem !important;
    }
    .rect-profile {
        background: #ffffff;
        box-shadow: 0px 0px 5px rgba(92, 167, 114, 0.25);
        border-radius: 16px;
        border: none;
    }
    .pointer {
        cursor: pointer;
    }
    .custom-modal {
        .modal-content {
            background: #ffffff;
            border-radius: 16px;
            border: none;
        }
    }
    .max-w-600 {
        max-width: 600px;
    }
    .max-w-400 {
        max-width: 400px;
    }
    .max-w-350 {
        max-width: 350px;
    }
    .mt-neg-50 {
        margin-top: -50px;
    }
    .p-2rem {
        padding: 2rem;
    }
    .custom-btn {
        border: none !important;
        background-color: transparent !important;
    }
    .b-none {
        border: none !important;
    }
    .custom-select {
        border: 1px solid $border-color !important;
        border-radius: 21px !important;
        padding: 0.59rem 1rem 0.59rem 1rem !important;
        &:focus {
            box-shadow: none !important;
        }
    }
}
// .custom-profile-menu {
//     .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
//         padding: 0px;
//     }
// }
.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    width: 17% !important;
}

/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #e1e1e1 #f8f8f8;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 12px;
}

*::-webkit-scrollbar-track {
    background: #f8f8f8;
}

*::-webkit-scrollbar-thumb {
    width: 10px;
    background-color: #e1e1e1;
    border-radius: 20px;
}

.vendor-modal-body {
    padding-top: 0 !important;
}
.border-primary {
    border: none;
    border-radius: 4px;
}

.img-text {
    position: relative;
    text-align: center;
    color: $white;
}
.fit-contain {
    object-fit: contain !important;
}
.m-backdrop {
    background-color: $dark;
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-transition: 0.5s;
    transition: all 0.3s linear;
}
.round-stock{
    width: 35px !important;
    height: 35px !important;;
    background: #ffffff;
    border: 1px solid #e1e1e1;
    box-sizing: border-box;
    box-shadow: 0px 0px 4px rgba(21, 131, 255, 0.2);
    border-radius: 21px;
    text-align: center;
}

.table-invent{
    tr {
        display: block
      }
      td.break {
        float: left;
        line-height: 22px;
      }
}
.wm-63{
    width: 63.66667%;
}