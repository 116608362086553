@import "../../../scss/utils/util.scss";
.custom-flush-accrodion {
    .accordion-button:not(.collapsed) {
        box-shadow: none !important;
        border: 2px solid $primary;
    }
    .accordion-button:focus {
        box-shadow: none !important;
        border: 2px solid $primary;
    }
    .accordion-button::after {
        content: "\002B" !important;
        background-image: none !important;
        width: auto !important;
        height: auto !important;
        color: $primary !important;
    }
    .accordion-button {
        padding: 0px 2px 0px 2px !important;
        width: auto !important;
        border: 2px solid $primary;
        border-radius: 2px !important;
    }
    .accordion-button:not(.collapsed)::after {
        content: "\2212" !important;
    }
}
.w-55 {
    width: 55% !important;
}
.h-1rem {
    height: 1rem;
}
.v-middle {
    vertical-align: middle !important;
}
// .custom-check-box {
//     .css-ahj2mt-MuiTypography-root {
//         font-family: $font-family-medium;
//         font-size: $f12;
//     }
//     .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
//     .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
//         color: $primary !important;
//     }

//     .css-i4bv87-MuiSvgIcon-root {
//         height: 0.8em;
//         width: 0.8em;
//     }
// }
// .custom-switch-btn {
//     .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
//         color: $white;
//     }
//     .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
//         background-color: $primary;
//         opacity: 1;
//     }
// }

.custom-file-upload {
    .file {
        position: relative;
        display: flex;
    }

    .file > input[type="file"] {
        display: none;
    }

    .file > label {
        font-size: 1rem;
        font-weight: 300;
        cursor: pointer;
        outline: 0;
        user-select: none;
        border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
        border-style: solid;
        border-radius: 4px;
        border-width: 1px;
        background-color: hsl(0, 0%, 100%);
        color: hsl(0, 0%, 29%);
        height: 80px;
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .file > label:hover {
        border-color: hsl(0, 0%, 21%);
    }

    .file > label:active {
        background-color: hsl(0, 0%, 96%);
    }

    .file > label > i {
        padding-right: 5px;
    }

    .file--upload > label {
        color: $primary;
        border-color: $primary;
    }

    .file--upload > label:hover {
        border-color: $primary;
        background-color: hsl(204, 86%, 96%);
    }

    .file--upload > label:active {
        background-color: hsl(204, 86%, 91%);
    }
}
.box-img {
    height: 80px;
    width: 80px;
}

// .custom-radio-btn {
//     .css-ahj2mt-MuiTypography-root {
//         font-family: $font-family-medium;
//         font-size: $f12;
//     }
//     .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
//         color: $primary !important;
//     }
// }

.mw-1000 {
    max-width: 1000px !important;
}

.inventory-accordion {
    .accordion-item .accordion-button {
        border-radius: 0;
        box-shadow: none;
        color: $dark !important;
    }
    .accordion-button:not(.collapsed) {
        color: $dark !important;
        background-color: transparent;
        box-shadow: none;
        &::after {
            background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
        }
    }
    .accordion-button::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
    }
}

.top-left-text {
    position: absolute;
    top: -10px;
    right: 20px;
    cursor: pointer;
    z-index: 10;
}

// .custom-check-box2 {
//     .css-ahj2mt-MuiTypography-root {
//         font-family: $font-family-medium;
//         font-size: $f16;
//     }
//     .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
//         color: $primary !important;
//     }
// }

.rounded-plus-btn {
    padding: 0.8rem !important;
    color: $primary !important;

    border-radius: 9px !important;
    border-color: $primary !important;
    background-color: transparent !important;
    line-height: 0.1 !important;
}

.rounded-close-btn {
    padding: 0.7rem  0.4rem 0.9rem 0.4rem !important;
    color: $danger !important;
    font-size: $f24 !important;
    border-radius: 9px !important;
    background-color: transparent !important;
    line-height: 0.1 !important;
    border: 1px solid $danger !important;
}
.w-95 {
    width: 94% !important;
}
.wm-90{
width:90% !important;
}
.w-auto {
    width: auto;
}
.hr01{
    height:0.1px !important;
}
.wm-12{
    width:12% !important;
}
.wm-35{
    width:35% !important
}