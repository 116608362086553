@import "../../../scss/utils/util.scss";

.top-border {
    border-top: 1px solid $border-grey;
    border-bottom: 1px solid $border-grey;
}

.insight-carousel-indicators {
    position: absolute !important;
    top: 100% !important;
    transform: translateY(100%) !important;
}

.insight-carousel-indicators button {
    @media only screen and (min-width: 765px) {
        width: 10px !important;
        height: 10px !important;
    }
    @media only screen and (max-width: 765px) {
        width: 10px !important;
        height: 10px !important;
    }
    margin: 0 10px !important;
}

// .insight-carousel-indicators .active {

// }
.carousel-indicators [data-bs-target] {
    border-radius: 51px !important;
    border-color: #000000 !important;
    background-color: #ffffff !important;
    border-top: none !important;
    box-sizing: border-box !important;
}
.carousel-indicators .active {
    background-color: $green 0% 0% no-repeat !important;
    border-radius: 51px !important;
    border-color: $green !important;
}
.carousel-indicators {
    background-color: $light 0% 0% no-repeat !important;
    border-color: #000000 !important;
}
.carousel-img {
    width: 100%;
    height: 250px;
    object-fit: cover;
}
.h-auto {
    height: auto;
}
.pointer {
    cursor: pointer;
}
.prod-accordion {
    .accordion-item .accordion-button {
        border-radius: 0;
        box-shadow: none;
        color: $dark !important;
    }
    .accordion-button:not(.collapsed) {
        color: $dark !important;
        background-color: transparent;
        box-shadow: none;
        &::after {
            background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
        }
    }
    .accordion-button::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
        background-size: 0.95rem;
    }
}
