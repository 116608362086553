@import "../../../scss/utils/util.scss";
.custom-excel-upload {
    .file {
        position: relative;
        display: flex;
    }

    .file > input[type="file"] {
        display: none;
    }

    .file > label {
        font-size: 1rem;
        font-weight: 300;
        cursor: pointer;
        outline: 0;
        user-select: none;
        border-color: $white;
        border-style: solid;
        border-radius: 21px;
        border-width: 2px;
        background-color: hsl(0, 0%, 100%);
        color: hsl(0, 0%, 29%);
        // box-shadow: 0 3px 10px grey;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    // .file > label:hover {
    //     // border-color: hsl(0, 0%, 21%);
    // }

    .file > label:active {
        background-color: hsl(0, 0%, 96%);
    }

    .file > label > i {
        padding-right: 5px;
    }

    .file--upload > label {
        color: $primary;
        border-color: $primary;
    }

    .file--upload > label:hover {
        border-color: $primary;
        background-color: hsl(204, 86%, 96%);
    }

    .file--upload > label:active {
        background-color: hsl(204, 86%, 91%);
    }
}
.custom-add-border{
   border: 1px solid $grey;
}